/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";


/* @import "assets/css/app/layout.scss"; */
/* 
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/css/demo/flags.css"; 
*/
html {
  height: 100%;
}

body{
  
  color: #495057;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

.p-component {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* height: 100%; */

}

/* this stops In chrome, the default "user agent stylesheet" from overriding my css when something is focused on  */
:focus-visible {
  outline: none !important;
}

.fill{
  width:100%;
  height:100%
}

.active-color{
  color: #1A613C
}

.inactive-color{
  color: #678073
}

/*NG PRIME TAB VIEW */
.p-tabview{
  height: 100%;
  width: 100%;
}
.p-tabview-panels {
  height: 100%;
  width:100%;
}

.p-tabview-panel {
  height: 100%;
  width:100%;
}

.p-progress-spinner-circle {
  stroke: #104D2D !important;
  fill: transparent
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: whitesmoke;
  /* border-color: black;
  color: black; */
}
/*
not selected
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-color: black;
  border-width: 1px;
  border: solid;
}
hover
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #e8e8e8;
  border-color: brown;
  color: navy;
}
selected
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-bottom: solid;
}

selected
.p-tabview {
  margin: 15px;
}*/
